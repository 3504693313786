window.$ = window.jQuery = require( 'jquery' );
require("jqtree");
import 'jqtree/jqtree.css';

class VideoLibrary {
    init() {
        $("#video-library").tree({
            dataUrl: '/admin/video-library-tree',
            autoOpen: false, // 1 = 1 level
            dragAndDrop: true,
            openedIcon: $('<i class="fas fa-folder-open" style="color: orange"></i>'),
            closedIcon: $('<i class="fas fa-folder"  style="color: darkorange"></i>'),
            onLoading : (isLoading,node,el)=>{
                if (isLoading) {
                    $("#video-loading").html("Loading data.... ");
                    console.log(el);
                    console.log(node);
                }
                else{
                    $("#video-loading").html("Finished.... ").fadeOut("slow");
                }
            },
            onLoadFailed : (r)=>{
                $("#video-loading").html("There was a problem loading the video and category data. Please speak with support. ");
                console.log(r);
            },
            onCanMoveTo: function(moved_node, target_node, position) {
                if (target_node.isVideo) {
                    // can move above or below but not inside
                    return (position !== 'inside');
                }
                else {
                    return true;
                }
            },
            onCreateLi: function(node, $li, is_selected) {
                // Add 'icon' span before title
                if (node.isVideo)
                    $li.find('.jqtree-title').before('<i class="fas fa-video"></i>');

                //    $li.find('.jqtree-title').before('[ EDIT ]');
            }

        }).jqTreeContextMenu({
            menu: '#context-menu',
            onContextMenuItem: function(e, node, $el){
                let action;
                let isVideo;
                let nodeId;
                if ($el.data("item")==="create-category"){
                    if (node.isVideo){
                        alert("You cannot create a category from a video");
                    }
                    else{
                        $("#category-image-preview").html("");
                        $('#category-modal-title').html("Create Category");
                        $('.within-category').html("Within: "+node.name);
                        $('#category_id').val(node.id);
                        $('#category_name').val("");
                        $('#category_action').val("create");
                        $('#category_thumbnail').val("");
                        //$('#category_evergreen').bootstrapToggle('off')

                        $('#category-modal').modal('show').find('input[type=text],textarea,select').filter(':visible:first').focus();
                    }
                }
                if ($el.data("item")==="add-video"){
                    $("#video-image-preview").html("");
                    $('#video-modal-title').html("Add new video");
                    $('.within-category').html("Within: "+node.name);
                    $('#video_id').val("");
                    $('#node_id').val(node.id);
                    $('#video_name').val("");
                    $('#video_evergreen').bootstrapToggle('off');
                    $('#video_category').val("");
                    $("#video_content").summernote('reset');
                    $('#video_action').val("create");
                    $('#video_thumbnail').val("");
                    $('#video-modal').modal('show').find('input[type=text],textarea,select').filter(':visible:first').focus();
                    $(".edit-only").hide();
                }
                if ($el.data("item")==="delete"){
                    if (node.isVideo){
                        if (confirm("You are about to delete the video "+node.name+" - Are you sure?")){
                            action="delete";
                            nodeId=node.id;
                            window.app.videoLibrary.processModalResponse(action,null,null,true,nodeId);
                        }
                    }
                    else{
                        if (confirm("You are about to delete the category "+node.name+" - including ALL videos and other categories that are within it. Are you absolutely sure?")){
                            action="delete";
                            nodeId=node.id;
                            window.app.videoLibrary.processModalResponse(action,null,null,false,nodeId);
                        }
                    }
                }
                if ($el.data("item")==="edit"){
                    if (node.isVideo){
                        if (node.thumbnail)
                            $("#video-image-preview").html("<img src=\""+node.thumbnail+"\">").show();
                        else
                            $("#video-image-preview").hide();
                        $('#video-modal-title').html("Edit video");
                        $('.within-category').html("");
                        $('#node_id').val(node.id);
                        $('#video_id').val(node.videoID);
                        $('#video_name').val(node.name);
                        $('#video_content').summernote("code",node.associated_text);
                        $('#video_resource').val(node.resource);
                        $('#video_evergreen').prop("checked",!!node.evergreen).bootstrapToggle(!!node.evergreen?"on":'off');
                        $('#video_action').val("edit");
                        $('#video_thumbnail').val(node.thumbnail);
                        $(".edit-only").show();
                        $('#video-modal').modal('show').find('input[type=text],textarea,select').filter(':visible:first').focus();
                    }
                    else{
                        $('#category-modal-title').html("Edit category");
                        if (node.thumbnail)
                            $("#category-image-preview").html("<img src=\""+node.thumbnail+"\">").show();
                        else
                            $("#category-image-preview").hide();
                        $('.within-category').html("");
                        $('#category_id').val(node.id);
                        $('#category_name').val(node.name);
                        $('#category_thumbnail').val(node.thumbnail);
                        //$('#category_evergreen').bootstrapToggle(node.evergreen?"on":'off')
                        $('#category_action').val("edit");
                        $('#category-modal').modal('show').find('input[type=text],textarea,select').filter(':visible:first').focus();
                    }
                }
            }
        });
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $(document).on("click","#btn-savecat",function(){
            const action=$("#category_action").val();
            const itemName=$("#category_name").val();
            const videoId=0;
            const isVideo=false;
            const nodeId =$("#category_id").val();
            const thumbnail =$("#category_thumbnail").val();
            const evergreen = false; //$("#category_evergreen").prop("checked");
            window.app.videoLibrary.processModalResponse(action,itemName,videoId,isVideo,nodeId,thumbnail,evergreen);

        });
        $(document).on("click","#btn-savevid",function(){
            const action=$("#video_action").val();
            const itemName=$("#video_name").val();
            const videoId=$("#video_id").val();
            const isVideo=true;
            const nodeId =$("#node_id").val();
            const thumbnail =$("#video_thumbnail").val();
            const resource =$("#video_resource").val();
            const content =$("#video_content").summernote('code');
            const evergreen = $('#video_evergreen').prop("checked"); //bootstrapToggle(node.evergreen?"on":'off')
            window.app.videoLibrary.processModalResponse(action,itemName,videoId,isVideo,nodeId,thumbnail,evergreen,content,resource);

        });
        $('#video-library').on(
            'tree.move',
            function(event) {
/*
                console.log('moved_node', event.move_info.moved_node);
                console.log('target_node', event.move_info.target_node);
                console.log('position', event.move_info.position);
                console.log('previous_parent', event.move_info.previous_parent);
*/
                //event.preventDefault()
                $.ajax({
                    type: "post",
                    url: "/admin/video-library-move-node",
                    data: {
                        moved_node: event.move_info.moved_node.id,
                        moved_node_type: event.move_info.moved_node.isVideo?"video":"category",
                        target_node: event.move_info.target_node.id,
                        target_node_type: event.move_info.target_node.isVideo?"video":"category",
                        position: event.move_info.position,
                        previous_parent: event.move_info.previous_parent.id
                    },
                    success: function (data) {
                        if (data.error){
                            notify.error("Error",data.error);
                        }
                    },
                    error: function (data) {
                        notify.error("Error",data);
                    }
                });
            }
        );
    }

    processModalResponse(action,itemName,videoId,isVideo,nodeId,thumbnail,evergreen=false,content="",resource=""){
        if (action){
            $.ajax({
                type: "post",
                url: "/admin/video-library-manage-node",

                data: {
                    action: action,
                    node_id: nodeId,
                    name: itemName,
                    videoID: videoId,
                    thumbnail: thumbnail,
                    evergreen: evergreen,
                    content: content,
                    resource: resource,
                    isVideo: isVideo
                },
                success: function (data) {
                    let $tree=$("#video-library");
                    const node=$tree.tree('getNodeById', nodeId);
                    if (data.error){
                        notify.error("Error",data.error);
                    }
                    else if (data.success){
                        if (action==="create"){
                            $tree.tree(
                                'appendNode',
                                data.nodeDetail,
                                node
                            );
                            if (isVideo)
                                $('#video-modal').modal('hide');
                            else
                                $('#category-modal').modal('hide');
                        }
                        if (action==="edit"){
                            $tree.tree(
                                'updateNode',
                                node,
                                data.nodeDetail
                            );
                            if (isVideo)
                                $('#video-modal').modal('hide');
                            else
                                $('#category-modal').modal('hide');
                        }
                        if (action === "delete"){
                            $tree.tree('removeNode', node);
                        }
                    }
                },
                error: function (data) {
                    notify.error("Error",data);
                }
            });
        }
    }
}

export default VideoLibrary;
