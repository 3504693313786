import Echo from "laravel-echo";

class WebSockets {
    constructor(key) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            wsHost: 'pusher.analyst-desk.com',
            key: key,
            wsPort: 6002,
            wssPort: 6002, // Added this
            forceTLS: true,
            disableStats: false
        });

        window.Echo.private(`training-centre`)
            .listen('.TrainingCentreOrderUpdated', (e) => {
                console.log("TrainingCentreOrderUpdated");
                console.log(e);
            });
        window.Echo.private(`training-centre`)
            .listen('.TrainingCentreVideo', (e) => {
                console.log("TrainingCentreVideo");
                console.log(e);
            });
        window.Echo.private(`training-centre`)
            .listen('.TrainingCentreStream', (e) => {
                console.log("TrainingCentreStream");
                console.log(e);
            });


        window.Echo.private(`headlines.5`)
            .listen('.Headline', (e) => {
                console.log("private headline");
                console.log(e);
            });

/*
        window.Echo.private(`curr-strength`)
            .listen('.LiveDataUpdateRollingMonthly', (e) => {
                console.log("LiveDataUpdateRollingMonthly");
                console.log(e);
            });
*/


        /*
                window.Echo.private(`support.message.1`)
                    .listen('.MailMessageStatusUpdated', (e) => {
                        console.log("private");
                        console.log(e);
                    });
                window.Echo.private(`tweetdeck.5`)
                    .listen('.TweetTweet', (e) => {
                        console.log("private");
                        console.log(e);
                    });
                window.Echo.private(`tweetdeck.5`)
                    .listen('.TweetChannel', (e) => {
                        console.log("private");
                        console.log(e);
                    });
        */
/*
        window.Echo.private(`headlines.5`)
            .listen('.Headline', (e) => {
                console.log("private headline");
                console.log(e);
            });

        window.Echo.private(`firstattempt`)
            .listen('.firstindicator', (e) => {
                console.log("dans message");
                console.log(e);
            });
*/

/*
        window.Echo.channel('support')
            .listen('MailMessageStatusUpdated', (e) => {
                /!*        danger() //red box with grey text and an hand icon
                          success() //green box with grey text and a check mark icon
                          warning() //yellow box with grey text and a warning icon
                          default() //changes no default styling
                        *!/
                toast().with({
                    duration: 4000,
                    speed: 1000,
                    positionX: 'end',
                    positionY: 'top',
                    color: 'blue',
                    tone: 900,
                    fontColor: 'blue',
                    fontTone: 200
                }).default('Unsnoozed', e.mailMessage.subject+" has just unsnoozed").show()

                console.log("pulic");
                console.log(e);
            });
        window.Echo.channel("support").listen('TaskUpdated', (e) => {
            /!*        danger() //red box with grey text and an hand icon
                      success() //green box with grey text and a check mark icon
                      warning() //yellow box with grey text and a warning icon
                      default() //changes no default styling
                    *!/
            console.log("public task update");
            console.log(e);

            const toaster=toast();
            if (e.type==="notification") {
                toaster.with({
                    duration: 4000,
                    speed: 1000,
                    positionX: 'end',
                    positionY: 'top',
                    color: 'blue',
                    tone: 900,
                    fontColor: 'blue',
                    fontTone: 200
                }).default(e.title, e.content).show();
            }
            if (e.type==="warning"){
                toaster.with({
                    duration: 4000,
                    speed: 1000,
                    positionX: 'end',
                    positionY: 'top',
                }).warning(e.title, e.content).show();
            }

        });
*/

    }
}

export default WebSockets;
