const { toast, snackbar } = require('tailwind-toast')

class Utils {
    constructor() {

    }
    randomPassword() {
        let char = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*=-_';
        let password = '';
        for(let i = 0; i<12; i++){
            password += char.charAt(Math.floor(Math.random() * char.length));
        }
        return password;
    }
    showToast(title, message, type) {
        if(type == "success") {
            toast().success(title, message).show();
        }
        else if(type == "danger") {
            toast().danger(title, message).show();
        }
        else if(type == "warning") {
            toast().warning(title, message).show();
        }
        else {
            toast().default(title, message).show();
        }
    }
    copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text);

        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    };
}

export default Utils;
