/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Application from "./modules/application";
import Swal from 'sweetalert2';
import 'alpine-magic-helpers';
import Echo from 'laravel-echo';
import 'livewire-sortable'
import 'tw-elements';
import "@fontsource/poppins"; 
window.Alpine = require('alpinejs');
const flatpickr = require("flatpickr");
require("flatpickr/dist/themes/dark.css");

const { toast, snackbar } = require('tailwind-toast')

window.toaster = toast;
window.snackBar = snackbar;
window.Pusher = require('pusher-js');
window.app = new Application(serverDomain,pusherKey);
window.Swal= Swal;




window.livewire.on('notify', data => {
    const type = data[0];
    const title = data[1];
    const message = data[2];
    const options = data[3];

    let settings =  {
        "duration" : 4000,
        "positionY" : "top",
        "positionX" : "center"
    }
    settings = {...settings,...options};

/*
"color", -> the background color of the snackbar or toast (tailwind colors)
"title", -> the title which is shown first in bold
"message", -> the message which is show second and not bold
"icon", -> the icon on the far left
"duration", -> how long the toast should stay for
"postion", -> positionY (top or bottom) and positionX (start, end, center)
"fontColor", -> color of the font
"fontTone", -> tailwind font tone (ie. text-blue-"500")
"tone", -> tailwind bg tone of the snackbar or toast (ie. bg-blue-"500")
"shape", -> square (rounded) or pill (rounded-full)
"speed" -> the speed the toast will appear and disappear (75, 100, 150, 200, 300, 500, 700, 1000)
 */
    switch (type) {
        case 'success':
            window.toaster().success(title,message).with(settings).show()
            break;
        case 'info':
            window.toaster().default(title,message).with(settings).show()
            break;
        case 'warning':
            window.toaster().warning(title,message).with(settings).show()
            break;
        case 'danger':
            window.toaster().danger(title,message).with(settings).show()
            break;
        default:
            window.toaster().default(title,message).with(settings).show()
    }
});
