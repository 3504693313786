import Utils from  "./utils";
import VideoLibrary from "./videoLibrary";
import WebSockets from "./webSockets";

class Application {
    constructor(domain,pusherKey) {
        this.debug=false;
        this.videoLibrary = new VideoLibrary();
        this.utils = new Utils();
        this.setupDragPanel();
        this.listenForModalEvents();
        this.webSockets = new WebSockets(pusherKey);
        this.domain = domain;
    }
    triggerOpenModalEvent() {
        // this will hide the existing button bar and remove the scroller from the body
        // and when the modal closes we will trigger a closeModalEvent which will  re-show the hidden toolbar
        // and remove the overflow hidden
        const openModal = new Event('openModalEvent');
        document.querySelector("body").dispatchEvent(openModal);
    }
    triggerCloseModalEvent() {
        // this will reshow the hidden toolbar and re set the overflow scrollbar
        const closeModal = new Event('closeModalEvent');
        document.querySelector("body").dispatchEvent(closeModal);
    }
    listenForModalEvents() {
        // catch a livewire emitted event and make it a system wide event
        Livewire.on('closeLiveWireModalEvent', (e) => {
            console.log('livewire closemodal');
            const closeModal = new Event('closeModalEvent');
            document.querySelector("body").dispatchEvent(closeModal);
        })
        Livewire.on('openLiveWireModalEvent', () => {
            console.log('livewire openmodal');
            const openModal = new Event('openModalEvent');
            document.querySelector("body").dispatchEvent(openModal);
        })

        let container = document.querySelector("body");
        container.addEventListener("closeModalEvent", () =>{
            console.log('normal close modal event');
            // when we open a full page modal
            document.querySelector(".finso-buttonbar").classList.remove("hidden");
            document.querySelector("body").classList.remove("overflow-hidden");
            console.log("looks like a Modal Was Closed")
        }, false);
        container.addEventListener("openModalEvent", () =>{
            console.log('normal open modal event');
            document.querySelector(".finso-buttonbar").classList.add("hidden");
            document.querySelector("body").classList.add("overflow-hidden");
        }, false);

    }
    setupDragPanel() {
        let dragItem = document.querySelector(".finso-buttonbar");
        let container = document.querySelector("body");

        let active = false;
        let currentX;
        let currentY;
        let initialX;
        let initialY;
        let xOffset = 0;
        let yOffset = 0;
        let activeItem = null;
        let dragging = false;

        container.addEventListener("touchstart", dragStart, false);
        container.addEventListener("touchend", dragEnd, false);
        container.addEventListener("touchmove", drag, false);

        container.addEventListener("mousedown", dragStart, false);
        container.addEventListener("mouseup", dragEnd, false);
        container.addEventListener("mousemove", drag, false);

        function dragStart(e) {
            if (e.target !== e.currentTarget) {
                if (!e.target.classList.contains('finso-buttonbar')) {
                    return;
                }
                console.log(e.target);
                active = true;
                dragging = false;

                // this is the item we are interacting with
                activeItem = e.target;

                if (activeItem.degrees === undefined) {
                    activeItem.degrees = 0;
                }

                if (activeItem) {
                    if (!activeItem.xOffset) {
                        activeItem.xOffset = 0;
                    }

                    if (!activeItem.yOffset) {
                        activeItem.yOffset = 0;
                    }

                    if (e.type === "touchstart") {
                        activeItem.initialX = e.touches[0].clientX - activeItem.xOffset;
                        activeItem.initialY = e.touches[0].clientY - activeItem.yOffset;
                    } else {
                        activeItem.initialX = e.clientX - activeItem.xOffset;
                        activeItem.initialY = e.clientY - activeItem.yOffset;
                    }
                }
            }
        }

        function dragEnd(e) {
            if (activeItem !== null) {
                activeItem.initialX = activeItem.currentX;
                activeItem.initialY = activeItem.currentY;
            }

            active = false;
            activeItem = null;
        }

        function drag(e) {
            if (active) {
                dragging = true;

                if (e.type === "touchmove") {
                    e.preventDefault();

                    activeItem.currentX = e.touches[0].clientX - activeItem.initialX;
                    activeItem.currentY = e.touches[0].clientY - activeItem.initialY;
                } else {
                    activeItem.currentX = e.clientX - activeItem.initialX;
                    activeItem.currentY = e.clientY - activeItem.initialY;
                }

                activeItem.xOffset = activeItem.currentX;
                activeItem.yOffset = activeItem.currentY;

                setTranslate(activeItem.currentX, activeItem.currentY, activeItem);
            }
        }

        function setTranslate(xPos, yPos, el) {
            el.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
        }
    }
    modalDelete(title, text, data, eventToFire) {
        window.Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Delete`,
            denyButtonText: `Don't delete`,
        })
        .then(result => {
            if (result.isConfirmed) {
                window.livewire.emit(eventToFire, data);
            }
        });
    }

}

export default Application;
